import * as React from "react"
import styled from 'styled-components'

const stages = [
  {
    text: "Idea",
    url: "/phase/idea",
    description:
      "Nothing built yet. Maybe still looking for an idea",
  },
  {
    text: "Build",
    url: "/phase/build",
    description:
      "You know what problem to solve. Starting to build or searching for market fit",
  },
  {
    text: "Scale",
    url: "/phase/scale",
    description:
      "Accelerating growth. Looking to increase your customer base",
  },
]

const StagesContainer = styled.div`
  display: flex
  flex-direction: row
`;

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Where are you?</title>
      <h1>Where are you in your startup journey?</h1>
      <p>Startup Trail Guide is the guidebook for the next steps in your journey. Learn from other peoples' experience and learn the well troden paths to your goal</p>
      <StagesContainer>
        {stages.map(stage => (
          <li key={stage.url}>
            <span>
              <a href={stage.url}
              >
                {stage.text}
              </a>
              <p>{stage.description}</p>
            </span>
          </li>
        ))}
      </StagesContainer>
    </main >
  )
}

export default IndexPage
